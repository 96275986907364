import { useEffect } from "react";

export const useHideItem = (item: CanvasItem) => {
    useEffect(() => {
        const el = document.getElementById(item._itemViewModel.id);
        if (!el) {
            return;
        }
        el.style.opacity = "0";

        // eslint-disable-next-line consistent-return
        return () => {
            if (el) {
                el.style.opacity = "1";
            }
        };
    }, [item]);
};
