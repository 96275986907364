import { m } from "framer-motion";
import React from "react";
import { useTranslationSSR, defineMessages } from "@vp/i18n-helper";
import { FinishProps } from "../FinishItemPreview";
import { useLightGradientId, useLightSize } from "./FinishProvider";
import { MaskFilter } from "./MaskFilter";

const messages = defineMessages({
    backgroundRaisedFoilGold: {
        id: "studio.components.raisedFoilGold.background",
        defaultMessage: "RaisedfoilGold finish background",
        description: {
            note: "Descriptive alt label for RaisedfoilGold finish background image "
        }
    }
});

export const RaisedFoilGoldFinish = ({ previewUrl, id, lightPos, maskSize }: FinishProps) => {
    const { t } = useTranslationSSR();
    const lightSize = useLightSize();
    const lightGradientId = useLightGradientId();

    return (
        <>
            <img src={previewUrl} className="photon-finish-background" alt={t(messages.backgroundRaisedFoilGold.id)} />
            <svg xmlns="http://www.w3.org/2000/svg" className="photon-finish-overlay">
                <defs>
                    <MaskFilter id={`photon-${id}`} previewUrl={previewUrl} maskSize={maskSize} isRaised />
                </defs>
                <g filter={`url(#photon-${id})`}>
                    <m.rect
                        fill={`url(#${lightGradientId})`}
                        style={{
                            y: lightPos.top,
                            x: lightPos.left,
                            width: lightSize,
                            height: lightSize,
                            opacity: "0.3",
                            mixBlendMode: "hard-light"
                        }}
                    />
                </g>
            </svg>
        </>
    );
};
RaisedFoilGoldFinish.displayName = "RaisedFoilGoldFinish";
