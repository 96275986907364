import { useIsMounted } from "@designer-suite";
import { useState, useEffect } from "react";

const SIZE_ATTRIBUTE = "previewSize";
const POSITION_ATTRIBUTE = "previewPosition";
const ROTATION_ATTRIBUTE = "rotation";
const ZINDEX_ATTRIBUTE = "zIndex";
const URL_ATTRIBUTE = "url";
const TEMPORARY_PREVIEW_ATTRIBUTE = "temporaryPreviewValues";

export const useItemProperties = (item: CanvasItem) => {
    const isMounted = useIsMounted();
    const itemViewModel = item._itemViewModel;
    const [size, setSize] = useState(itemViewModel.get(SIZE_ATTRIBUTE));
    const [position, setPosition] = useState(itemViewModel.get(POSITION_ATTRIBUTE));
    const [rotation, setRotation] = useState(itemViewModel.get(ROTATION_ATTRIBUTE));
    const [zIndex, setZIndex] = useState(itemViewModel.model.get(ZINDEX_ATTRIBUTE));
    const [previewUrl, setPreviewUrl] = useState<string>();
    const [transformOrigin, setTransformOrigin] = useState<{ x: number; y: number }>();

    useEffect(() => {
        // Set the maskUrl on load
        itemViewModel.get(URL_ATTRIBUTE).then((newUrl: string) => {
            if (isMounted()) {
                setPreviewUrl(newUrl);
            }
        });

        const handleChangePosition = () => {
            const newPosition = itemViewModel.get(POSITION_ATTRIBUTE);
            if (newPosition && isMounted()) {
                setPosition(newPosition);
            }
        };

        const handleChangeSize = () => {
            const newSize = itemViewModel.get(SIZE_ATTRIBUTE);
            if (newSize && isMounted()) {
                setSize(newSize);
            }
        };

        const handleChangeRotation = () => {
            const newRotation = itemViewModel.get(ROTATION_ATTRIBUTE);
            if (newRotation && isMounted()) {
                setRotation(newRotation);
            }
        };
        const handleChangeZIndex = () => {
            const newZIndex = itemViewModel.get(ZINDEX_ATTRIBUTE);
            if (newZIndex && isMounted()) {
                setZIndex(newZIndex);
            }
        };

        const handleChangeUrl = async () => {
            const urlPromise = itemViewModel.get(URL_ATTRIBUTE);
            const url = await urlPromise;
            const imgRes = await fetch(url);
            const mask = imgRes.redirected ? imgRes.url : url;
            if (mask && isMounted()) {
                setPreviewUrl(mask);
            }
        };

        const handleChangeTemporaryPreviewValues = () => {
            const newPreviewValues = itemViewModel.get(TEMPORARY_PREVIEW_ATTRIBUTE);

            if (!isMounted()) {
                return;
            }

            setPosition({
                left: newPreviewValues.left,
                top: newPreviewValues.top
            });

            setRotation(newPreviewValues.rotation);
            setTransformOrigin(newPreviewValues.transformOrigin);

            // If this is a text field don't show the preview while resizing
            // This looks really broken and for some reason it is disabled in non-finish previews also
            if (itemViewModel.get("module") !== "TextFieldViewModel") {
                setSize({
                    height: newPreviewValues.height,
                    width: newPreviewValues.width
                });
            }
        };

        itemViewModel.on(`change:${POSITION_ATTRIBUTE}`, handleChangePosition);
        itemViewModel.on(`change:${SIZE_ATTRIBUTE}`, handleChangeSize);
        itemViewModel.on(`change:${ROTATION_ATTRIBUTE}`, handleChangeRotation);
        itemViewModel.on(`model:change:${ZINDEX_ATTRIBUTE}`, handleChangeZIndex); // note: zindex is on the model
        itemViewModel.on(`change:${URL_ATTRIBUTE}`, handleChangeUrl);
        itemViewModel.on(`change:${TEMPORARY_PREVIEW_ATTRIBUTE}`, handleChangeTemporaryPreviewValues);

        // eslint-disable-next-line consistent-return
        return () => {
            itemViewModel.off(`change:${POSITION_ATTRIBUTE}`, handleChangePosition);
            itemViewModel.off(`change:${SIZE_ATTRIBUTE}`, handleChangeSize);
            itemViewModel.off(`change:${ROTATION_ATTRIBUTE}`, handleChangeRotation);
            itemViewModel.off(`model:change:${ZINDEX_ATTRIBUTE}`, handleChangeZIndex);
            itemViewModel.off(`change:${URL_ATTRIBUTE}`, handleChangeUrl);
            itemViewModel.off(`change:${TEMPORARY_PREVIEW_ATTRIBUTE}`, handleChangeTemporaryPreviewValues);
        };
    }, [isMounted, itemViewModel]);

    const isImage = itemViewModel.get("module") === "UploadedImageViewModel";
    const id = itemViewModel.get("id");

    return {
        size,
        position,
        rotation,
        zIndex,
        previewUrl,
        transformOrigin,
        isImage,
        id
    };
};
