import React from "react";

type MaskFilterProps = {
    id: string;
    previewUrl: string;
    isRaised: boolean;
    maskSize: {
        width: number;
        height: number;
    };
};

export const MaskFilter = ({ id, previewUrl, isRaised, maskSize }: MaskFilterProps) => {
    return (
        <filter id={id}>
            <feImage href={previewUrl} result="mask" height={maskSize.height} width={maskSize.width} x="0" y="0" />
            {isRaised && <feMorphology in="mask" result="shine" operator="erode" radius="1" />}
            <feComposite in2={isRaised ? "shine" : "mask"} in="SourceGraphic" result="overlay" operator="in" />
        </filter>
    );
};
MaskFilter.displayName = "MaskFilter";
