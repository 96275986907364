import { MotionValue, useTransform } from "framer-motion";
import React from "react";
import { useFinishType } from "@utilities";
import { MetallicFinish } from "./finishes/Metallic";
import { RaisedFoilGlitterSilverFinish } from "./finishes/RaisedFoilGlitterSilver";
import { RaisedFoilGoldFinish } from "./finishes/RaisedFoilGold";
import { RaisedInkFinish } from "./finishes/RaisedInk";
import { useItemProperties } from "./hooks/useItemProperties";
import { useLightPosition } from "./lighting/useLightPosition";
import "./FinishItemPreview.scss";
import { useHideItem } from "./hooks/useHideItem";
import { useLightSize } from "./finishes/FinishProvider";
import { RaisedFoilSilverFinish } from "./finishes/RaisedFoilSilver";

export type FinishItemPreviewProps = {
    item: CanvasItem;
};

export type FinishProps = {
    previewUrl: string;
    id: string;
    lightPos: {
        top: MotionValue;
        left: MotionValue;
    };
    maskSize: {
        width: number;
        height: number;
    };
    isImage?: boolean;
};

export const FinishItemPreview = ({ item }: FinishItemPreviewProps) => {
    const finish = useFinishType();
    const { id, size, position, rotation, zIndex, previewUrl, transformOrigin, isImage } = useItemProperties(item);
    const lightSize = useLightSize();

    // Get the relative light position based on the item position
    const { lightX, lightY } = useLightPosition();
    const lightPos = {
        left: useTransform(lightX, itemLightX => itemLightX - (position.left + lightSize / 2)),
        top: useTransform(lightY, itemLightY => itemLightY - (position.top + lightSize / 2))
    };

    // Hide the original item
    useHideItem(item);

    if (!finish) {
        return null;
    }

    // If for some reason the previewUrl doesn't exist then don't render
    if (!previewUrl) {
        return null;
    }

    const finishProps: FinishProps = {
        previewUrl,
        id,
        lightPos,
        maskSize: size,
        isImage
    };

    return (
        <div
            id={`photon-container-${id}`}
            style={{
                ...size,
                ...position,
                transform: `rotate(${-rotation}deg)`,
                transformOrigin: transformOrigin ? `${transformOrigin.x}px ${transformOrigin.y}px` : undefined,
                zIndex
            }}
        >
            {
                {
                    RaisedFoilGold: <RaisedFoilGoldFinish {...finishProps} />,
                    RaisedFoilSilver: <RaisedFoilSilverFinish {...finishProps} />,
                    RaisedInk: <RaisedInkFinish {...finishProps} />,
                    Metallic: <MetallicFinish {...finishProps} />,
                    RaisedFoilGlitterSilver: <RaisedFoilGlitterSilverFinish {...finishProps} />
                }[finish]
            }
        </div>
    );
};
FinishItemPreview.displayName = "FinishItemPreview";
