import { useStudioLayout } from "@shared/features/ResponsiveDesign";
import { useSpring } from "framer-motion";
import { useEffect } from "react";
import { useCanvasBoundingRect } from "../hooks/useCanvasBoundingRect";

/**
 * Hook that gets the light position relative to the canvasViewModel
 */
export const useLightPosition = () => {
    const lightX = useSpring(0, { bounce: 0, duration: 0.7 });
    const lightY = useSpring(0, { bounce: 0, duration: 0.7 });
    const canvasBoundingRect = useCanvasBoundingRect();
    const { isSmall, isMedium } = useStudioLayout();
    // There is a bug in firefox where matchMedia does not work: https://bugzilla.mozilla.org/show_bug.cgi?id=1638556
    const hasPointer = !(isSmall || isMedium);

    useEffect(() => {
        // If there is a mouse, use a moving light source
        // In a moving light source, the light is positioned at the position of the mouse
        // Set the light x and y based on the mouse's clientX/Y position and canvas offset
        if (hasPointer) {
            const updateMousePosition = (ev: MouseEvent) => {
                lightX.set(ev.clientX - canvasBoundingRect.left);
                lightY.set(ev.clientY - canvasBoundingRect.top);
            };

            window.addEventListener("mousemove", updateMousePosition);

            // eslint-disable-next-line consistent-return
            return () => window.removeEventListener("mousemove", updateMousePosition);
        }

        // Otherwise, use a fixed light source
        // In a fixed light source, the light is fixed outside of the device
        // The device's orientation is used to reposition the light
        // Set the light x and y based on the relative rotation of the device
        const updateDeviceOrientation = (e: DeviceOrientationEvent) => {
            if (e.gamma === null || e.beta === null) {
                return;
            }

            const degX = e.gamma;
            // NOTE: Enable this to change the shine on Y direction
            // It was hard to know what degree the user is going to hold their phone at so disabling this
            // Normalize to the user is holding the phone in portrait at 70deg back
            // const degY = e.beta - 70;

            // Get the relative rotation within the allowed range
            const range = 60;
            const relX = degX > 0 ? Math.min(0.5, degX / range) : Math.max(-0.5, degX / range);
            // const relY = degY > 0 ? Math.min(0.5, degY / range) : Math.max(-0.5, degY / range);

            lightX.set(canvasBoundingRect.width * (0.5 - relX));
            lightY.set(canvasBoundingRect.height * 0.5);
        };

        window.addEventListener("deviceorientation", updateDeviceOrientation);

        // eslint-disable-next-line consistent-return
        return () => window.removeEventListener("deviceorientation", updateDeviceOrientation);
    }, [canvasBoundingRect, lightX, lightY, hasPointer]);

    return { lightX, lightY };
};
