import React, { createContext, FC, useContext } from "react";
import { LazyMotion, domAnimation } from "framer-motion";

const FinishContext = createContext<
    | {
          lightGradientId: string;
          metallicNoiseId: string;
          glitterNoiseId: string;
          lightSize?: number;
      }
    | undefined
>(undefined);

export const useLightGradientId = () => {
    return useContext(FinishContext)?.lightGradientId;
};

export const useMetallicNoiseId = () => {
    return useContext(FinishContext)?.metallicNoiseId;
};

export const useGlitterNoiseId = () => {
    return useContext(FinishContext)?.glitterNoiseId;
};

export const useLightSize = () => {
    return useContext(FinishContext)?.lightSize || 400;
};

/**
 * The FinishProvider renders an invisible SVG with the definitions needed for the finishes.
 * Once the filter is rendered, the filterId can be used to reference it through the SVG filter property
 */
export const FinishProvider: FC = ({ children }) => {
    const lightGradientId = "photon-light-gradient";
    const metallicNoiseId = "photon-metallic-noise";
    const glitterNoiseId = "photon-glitter-noise";
    const lightSize = window.innerWidth / 2;

    return (
        <FinishContext.Provider
            value={{
                lightGradientId,
                metallicNoiseId,
                glitterNoiseId,
                lightSize
            }}
        >
            {/* Render the appropriate filter definitions based on the enabled finish types  */}
            <LazyMotion features={domAnimation} strict>
                <svg xmlns="http://www.w3.org/2000/svg">
                    <defs>
                        <radialGradient id={lightGradientId}>
                            <stop offset="0%" stopColor="#fff" />
                            <stop offset="100%" stopColor="transparent" />
                        </radialGradient>
                        <pattern id={metallicNoiseId} x="0" y="0" width="50" height="50" patternUnits="userSpaceOnUse">
                            <image
                                href="https://vortex.documents.cimpress.io/finishes/foil/v1/base"
                                x="0"
                                y="0"
                                width="50"
                                height="50"
                            />
                        </pattern>
                        <pattern id={glitterNoiseId} x="0" y="0" width="200" height="200" patternUnits="userSpaceOnUse">
                            <image
                                href="https://uploads.documents.cimpress.io/v1/uploads/e48803af-8097-4edd-be90-81b038cf7911~110?variant=original&tenant=doc-platform"
                                x="0"
                                y="0"
                                width="200"
                                height="200"
                            />
                        </pattern>
                    </defs>
                </svg>
                {/* Render the children which can use these filters */}
                {children}
            </LazyMotion>
        </FinishContext.Provider>
    );
};
FinishProvider.displayName = "FinishProvider";
