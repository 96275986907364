import { useDesigner } from "@designer-suite";
import { useRef, useEffect } from "react";

// Update the position of the canvas on scroll or on zoomFactor change
export const useCanvasBoundingRect = () => {
    const designer = useDesigner();
    const canvasPosition = useRef({ top: 0, left: 0, width: 0, height: 0 });

    useEffect(() => {
        const canvasEls = document.getElementsByClassName("dcl-canvas");
        if (!designer || canvasEls.length === 0) {
            return;
        }

        const canvasEl = canvasEls[0];
        const canvasViewModel = designer?.documentRepository.getActiveCanvasViewModel();

        if (!canvasViewModel) {
            return;
        }

        const updateCanvasPosition = () => {
            const { top, left, width, height } = canvasEl?.getBoundingClientRect();
            canvasPosition.current.top = top;
            canvasPosition.current.left = left;
            canvasPosition.current.width = width;
            canvasPosition.current.height = height;
        };

        updateCanvasPosition();
        document.addEventListener("scroll", updateCanvasPosition);
        canvasViewModel.on("change:zoomFactor", updateCanvasPosition);

        // eslint-disable-next-line consistent-return
        return () => {
            document.removeEventListener("scroll", updateCanvasPosition);
            canvasViewModel.off("change:zoomFactor", updateCanvasPosition);
        };
    }, [designer]);

    return canvasPosition.current;
};
